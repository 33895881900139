import has from 'lodash/has';
import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';

import Config from '../../config';
import { mapCapturesDistributionToCalendar, mapMonthlyCapturesDistributionToCalendar } from '../../processing/captures';
import { byCaptureKey, byDayKey } from '../../reducers/entities/captures';
import { getDayOfYearByYMD } from '../../utils/datetime/day-of-year';
import dateToTimestamp from '../../utils/datetime/date-to-timestamp';
import dateTimeToTimestamp from '../../utils/datetime/datetime-to-timestamp';
import inUTC from '../../utils/datetime/in-utc';
import timestampToDatetime from '../../utils/datetime/timestamp-to-datetime';
import { extractStatusStatsOfYear } from '../../utils/statuses';
import wbUrl from '../../utils/wb_url';

import { getCaptures, getMonth } from '../entities/captures';
import * as searchRequestSelector from './search-request';

const getCalendar = state => state.ui.calendar;

const getHighlightedRaw = createSelector(
  [getCalendar],
  (calendar) => calendar.highlighted
);

export const getHighlighted = createSelector(
  [getHighlightedRaw],
  (highlighted) => (highlighted && {
    ...highlighted,
    dayOfYear: getDayOfYearByYMD(highlighted.year, highlighted.month, highlighted.day)
  })
);

export const getHighlightedDate = createSelector(
  [getHighlighted],
  (highlighted) => {
    if (!(highlighted && ('month' in highlighted) && ('day' in highlighted))) {
      return null;
    }

    return inUTC(highlighted.year, highlighted.month, highlighted.day);
  }
);

const getHighlightedTimestamp = createSelector(
  [getHighlightedDate],
  (date) => {
    if (!date) {
      return null;
    }

    return dateToTimestamp(date);
  }
);

export const getSelected = createSelector(
  [getCalendar],
  (calendar) => calendar.selected
);

export const getCurrentCapture = createSelector(
  [getSelected],
  (selected) => {
    const capture = selected.capture;
    if (capture) {
      return {
        url: wbUrl(capture.datetime, capture.url),
        caption: timestampToDatetime(capture.datetime).toUTCString(),
        cl: capture.cl
      };
    } else {
      return null;
    }
  }
);

export const getSelectedYear = createSelector(
  [getSelected],
  (selected) => selected.year
);

export const getSelectedDate = createSelector(
  [getSelected],
  (selected) => selected && inUTC(selected.year, selected.month || 0, selected.day || 1)
);

export const getSelectedTimestamp = createSelector(
  [getSelectedDate],
  (date) => date && dateTimeToTimestamp(date)
);

export const getCapturesOfSubmittedQuery = createSelector(
  [getCaptures, searchRequestSelector.getSubmittedQueryText],
  (captures, queryText) => captures[queryText]
);

export const getMonthlyCapturesDistributionOfYear = createSelector(
  [getCapturesOfSubmittedQuery, getSelectedYear],
  (captures, year) => {
    return get(captures, ['distribution', 'byYear', year], []);
  }
);

export const getNumOfCapturesOfSubmittedQuery = createSelector(
  [getCapturesOfSubmittedQuery, getSelectedYear],
  (captures, year) => {
    const distribution = get(captures, ['distribution', 'byYear', year], []);
    return distribution.reduce((acc, n) => acc + n, 0);
  }
);

export const getTypeOfCalendarGrid = createSelector(
  [getNumOfCapturesOfSubmittedQuery],
  (numOfCaptures) => {
    if (numOfCaptures > Config.calendar.max_num_of_captures_for_single_year_request) {
      return 'monthly';
    } else {
      return 'annual';
    }
  }
);

export const getMonthsWithCaptures = createSelector(
  [getCapturesOfSubmittedQuery, getSelectedYear],
  (captures, year) => {
    const distribution = get(captures, ['distribution', 'byYear', year], []);
    return distribution
      .map((numOfCaptures, month) => ({ numOfCaptures, month: month + 1 }))
      .filter(({ numOfCaptures }) => numOfCaptures > 0);
  }
);

export const hasCapturesOfSubmittedQueryIsValid = createSelector(
  [getCapturesOfSubmittedQuery],
  (captures) => !get(captures, 'distribution.inProgress', true)
);

export const hasCapturesOfSubmittedQuery = createSelector(
  [getCapturesOfSubmittedQuery],
  (captures) => captures ? !captures.noCaptures : undefined
);

const getCapturesDistributionOfTheSelectedYear = createSelector(
  [getCapturesOfSubmittedQuery, getSelectedYear],
  (captures, year) => get(captures, ['byYear', year], {})
);

const getMonthlyCapturesDistributionOfTheSelectedYear = createSelector(
  [getCapturesOfSubmittedQuery, getSelectedYear],
  (captures, year) => get(captures, ['byMonth', year], {})
);

export const getCapturesDistributionOfTheSelectedYearError = createSelector(
  [getCapturesDistributionOfTheSelectedYear],
  captures => captures.error
);

export const getCalendarSchemeOfTheSelectedYear = createSelector(
  [getSelectedYear],
  (year) => mapCapturesDistributionToCalendar({}, year)
);

export const getCapturesDistributionOfTheSelectedYearItems = createSelector(
  [getCapturesDistributionOfTheSelectedYear, getSelectedYear],
  (captures, year) => mapCapturesDistributionToCalendar(captures.items, year)
);

export const getCapturesDistributionOfTheSelectedYearAndMonthItems = createSelector(
  [getMonthlyCapturesDistributionOfTheSelectedYear, getSelectedYear, getMonth],
  (months, year, monthIdx) => {
    const items = get(months, [monthIdx, 'items']);
    if (!items) {
      return null;
    }
    return mapMonthlyCapturesDistributionToCalendar(items, year, monthIdx);
  }
);

export const isCapturesDistributionOfTheSelectedYearAndMonthInProgress = createSelector(
  [getMonthlyCapturesDistributionOfTheSelectedYear, getSelectedYear, getMonth],
  (months, year, monthIdx) => {
    return get(months, [monthIdx, 'inProgress']);
  }
);

export const getCapturesDistributionOfTheSelectedYearAndMonthError = createSelector(
  [getMonthlyCapturesDistributionOfTheSelectedYear, getSelectedYear, getMonth],
  (months, year, monthIdx) => {
    return get(months, [monthIdx, 'error']);
  }
);

export const getCapturesDistributionOfTheSelectedYearMonthlyItems = createSelector(
  [getMonthlyCapturesDistributionOfTheSelectedYear, getSelectedYear],
  (months, year) => {
    return Object.entries(months)
      .reduce((acc, [monthId, monthData]) => {
        acc[monthId - 1] = monthData;
        return acc;
      }, new Array(12).fill({}))
      .map((monthData, monthIdx) => {
        const monthItems = monthData && monthData.items;
        const weeks = mapMonthlyCapturesDistributionToCalendar(monthItems, year, monthIdx);
        return {
          ...monthData,
          weeks
        };
      });
  }
);

export const getMaxNumberOfCapturesInOneDayOfYear = createSelector(
  [getCapturesDistributionOfTheSelectedYear],
  captures => {
    const counts = Object.values(captures.items).map(i => i.cn);
    return Math.log(Math.max(...counts) + 1);
  }
);

export const getMinNumberOfCapturesInOneDayOfYear = createSelector(
  [getCapturesDistributionOfTheSelectedYear],
  captures => {
    const counts = Object.values(captures.items).map(i => i.cn);
    return Math.log(Math.min(...counts) + 1);
  }
);

export function applyToAllItemCountOfMonths (months, method, defaultValue) {
  const monthInstances = Object.values(months);
  if (monthInstances.length === 0) {
    return 0;
  }

  return method(
    ...monthInstances
      .filter(month => month.items)
      .map(month =>
        Object.values(month.items).reduce(
          (acc, i) => method(acc, i.cn),
          defaultValue
        )
      )
  );
}

export const getMaxNumberOfMonthlyCapturesInOneDayOfYear = createSelector(
  [getMonthlyCapturesDistributionOfTheSelectedYear],
  months => Math.log(applyToAllItemCountOfMonths(months, Math.max, 0) + 1)
);

export const getMinNumberOfMonthlyCapturesInOneDayOfYear = createSelector(
  [getMonthlyCapturesDistributionOfTheSelectedYear],
  months => Math.log(applyToAllItemCountOfMonths(months, Math.min, Number.MAX_VALUE) + 1)
);

export const isCapturesDistributionOfTheSelectedYearInProgress = createSelector(
  [getCapturesDistributionOfTheSelectedYear],
  captures => captures.inProgress
);

// export const isCapturesDistributionOfTheSelectedYearAndMonthInProgress = createSelector(
//   [getMonthlyCapturesDistributionOfTheSelectedYear],
//   months => {
//     const monthInstances = Object.values(months);
//     if (monthInstances.length === 0) {
//       return false;
//     }
//
//     return monthInstances.some((month) => {
//       return month.inProgress;
//     });
//   }
// );

export const hasCapturesDistributionOfTheSelectedYear = createSelector(
  [getCapturesDistributionOfTheSelectedYear],
  captures => 'items' in captures
);

// export const hasMonthlyCapturesDistributionOfTheSelectedYear = createSelector(
//   [getMonthlyCapturesDistributionOfTheSelectedYear],
//   months => {
//     const monthInstances = Object.values(months);
//     if (monthInstances.length > 0) {
//       return monthInstances.every(month => 'items' in month);
//     } else {
//       return false;
//     }
//   }
// );

export const getCapturesOfTheSelectedYearError = createSelector(
  [getCapturesDistributionOfTheSelectedYear],
  (captures) => captures.error
);

export const getCapturesOfTheSelectedYearList = createSelector(
  [getCapturesDistributionOfTheSelectedYear],
  (captures) => captures.items
);

export const capturesOfTheSelectedYearInProgress = createSelector(
  [getCapturesDistributionOfTheSelectedYear],
  (captures) => captures.inProgress
);

export const hasCapturesOfTheSelectedYear = createSelector(
  [getCapturesOfTheSelectedYearList],
  (captures) => !!captures
);

export const getCapturesSummaryOfTheSelectedYear = createSelector(
  [getCapturesOfTheSelectedYearList],
  (captures) => extractStatusStatsOfYear(captures)
);

export const hasUrlCapturesDistribution = createSelector(
  [getCapturesOfSubmittedQuery],
  captures => has(captures, 'distribution.byYear')
);

export const getCapturesDistributionByYears = createSelector(
  [getCapturesOfSubmittedQuery],
  captures => get(captures, 'distribution.byYear')
);

export const isUrlCapturesDistributionInProgress = createSelector(
  [getCapturesOfSubmittedQuery],
  captures => get(captures, 'distribution.inProgress', false)
);

export const getUrlCapturesDistributionError = createSelector(
  [getCapturesOfSubmittedQuery],
  captures => get(captures, 'distribution.error')
);

export const getHighlightedDayData = createSelector(
  [getCapturesDistributionOfTheSelectedYear, getHighlightedDate],
  (captures, date) => {
    if (!date) {
      return null;
    }

    const key = (date.getUTCMonth() + 1).toString() + date.getUTCDate().toString().padStart(2, '0');
    const total = get(captures, ['items', key, 'cn']);
    if (total === undefined) {
      return null;
    }
    const res = { total };
    return res;
  }
);

export const getHighlightedDayDataFromMonthlyData = createSelector(
  [getMonthlyCapturesDistributionOfTheSelectedYear, getHighlightedDate],
  (months, date) => {
    if (!date) {
      return null;
    }

    const total = get(months[date.getUTCMonth()], ['items', date.getUTCDate(), 'cn']);
    if (total === undefined) {
      return null;
    }
    return { total };
  }
);

export const getCapturesGroupByCollections = createSelector(
  [getCapturesOfSubmittedQuery, getSelectedYear],
  (captures, year) => get(captures, [byCaptureKey, year, 'items'])
);

export const isCapturesGroupByCollectionsInProgress = createSelector(
  [getCapturesOfSubmittedQuery, getSelectedYear],
  (captures, year) => get(captures, [byCaptureKey, year, 'inProgress'])
);

export const getCapturesGroupByCollectionsError = createSelector(
  [getCapturesOfSubmittedQuery, getSelectedYear],
  (captures, year) => get(captures, [byCaptureKey, year, 'error'])
);

export const hasCapturesGroupByCollectionsForYear = createSelector(
  [getCapturesOfSubmittedQuery, getSelectedYear],
  (captures, year) => has(captures, [byCaptureKey, year])
);

export const getAllPopupCapturesItem = createSelector(
  [getCapturesOfSubmittedQuery, getHighlightedTimestamp],
  (captures, timestamp) => get(captures, [byDayKey, timestamp, 'items'])
);

export const getAllPopupCapturesError = createSelector(
  [getCapturesOfSubmittedQuery, getHighlightedTimestamp],
  (captures, timestamp) => {
    return get(captures, [byDayKey, timestamp, 'error']);
  }
);

export const isAllPopupCapturesInProgress = createSelector(
  [getCapturesOfSubmittedQuery, getHighlightedTimestamp],
  (captures, timestamp) => {
    return get(captures, [byDayKey, timestamp, 'inProgress'], false);
  }
);

export const getSparklineFistYear = createSelector(
  [getCapturesOfSubmittedQuery],
  (captures) => captures && Number.parseInt(captures.firstYear)
);

export const getSparklineLastYear = createSelector(
  [getCapturesOfSubmittedQuery],
  (captures) => captures && Number.parseInt(captures.lastYear)
);
