/**
 * Extract statuses from year captures
 *
 * @param byDay
 * @return {any}
 */
export function extractStatusStatsOfYear (byDay) {
  return Object.values(byDay || {})
    .reduce((acc, { st, cn }) => {
      let key;
      if (st === '-') {
        key = '-';
      } else {
        key = `${Math.round(st / 100)}xx`;
      }
      acc[key] += cn;
      return acc;
    }, { '2xx': 0, '3xx': 0, '4xx': 0, '5xx': 0, '-': 0 });
}

export function extractStatusStatsOfDay (day) {
  return day.st
    .map(
      status => status && status !== '-' ? status.toString().charAt(0) + 'xx' : status
    )
    .reduce((prev, status) => {
      prev[status]++;
      return prev;
    }, { '2xx': 0, '3xx': 0, '4xx': 0, '5xx': 0, undefined: 0, '-': 0 });
}
