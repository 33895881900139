import { createReducer } from '@reduxjs/toolkit';
import has from 'lodash/has';
import reduce from 'lodash/reduce';
import set from 'lodash/set';
import sum from 'lodash/sum';

import { actionTypes as searchResultActionTypes } from './search-result';

import Config from '../../config';
import { processCollections } from '../../processing/collection';
import { restoreStatus } from '../../processing/status';
import {
  isCapturesCollectionsInProgress,
  isCapturesByYearInProgress,
  isCapturesByMonthInProgress,
  isCapturesDetailsInProgress,
  isCapturesDistributionInProgress
} from '../../selectors/entities/captures';
import * as calendarSelector from '../../selectors/ui/calendar';
import * as searchRequestSelector from '../../selectors/ui/search-request';
import { prepareUrl } from '../../utils/api-url-processor';
import { canIUseBetaFeature } from '../../utils/can-i-use-beta-feature';
import timestampToYear from '../../utils/datetime/timestamp-to-year';
import { fetchActionSimplified } from '../../utils/fetch-action';
import { getSearchItemsResult } from '../../utils/get-search-items-result';
import { types } from '../../utils/query-type';

const namespace = 'ENTITIES/CAPTURES';

export const actionTypes = {
  ALL_CAPTURES_OF_DAY_REQUEST: `${namespace}/ALL_CAPTURES_OF_DAY:REQUEST`,
  ALL_CAPTURES_OF_DAY_RECEIVED: `${namespace}/ALL_CAPTURES_OF_DAY:RECEIVED`,
  ALL_CAPTURES_OF_DAY_ERROR: `${namespace}/ALL_CAPTURES_OF_DAY:ERROR`,

  CAPTURES_DISTRIBUTION_REQUEST: `${namespace}/DISTRIBUTION:REQUEST`,
  CAPTURES_DISTRIBUTION_RECEIVED: `${namespace}/DISTRIBUTION:RECEIVED`,
  CAPTURES_DISTRIBUTION_ERROR: `${namespace}/DISTRIBUTION:ERROR`,

  CAPTURES_YEAR_DETAILS_REQUEST: `${namespace}/CAPTURES:YEAR_DETAILS:REQUEST`,
  CAPTURES_YEAR_DETAILS_RECEIVED: `${namespace}/CAPTURES:YEAR_DETAILS:RECEIVED`,
  CAPTURES_YEAR_DETAILS_ERROR: `${namespace}/CAPTURES:YEAR_DETAILS:ERROR`,

  CAPTURES_YEAR_DISTRIBUTION_REQUEST: `${namespace}/YEAR_DISTRIBUTION:REQUEST`,
  CAPTURES_YEAR_DISTRIBUTION_RECEIVED: `${namespace}/YEAR_DISTRIBUTION:RECEIVED`,
  CAPTURES_YEAR_DISTRIBUTION_ERROR: `${namespace}/YEAR_DISTRIBUTION:ERROR`,

  CAPTURES_MONTH_DISTRIBUTION_REQUEST: `${namespace}/MONTH_DISTRIBUTION:REQUEST`,
  CAPTURES_MONTH_DISTRIBUTION_RECEIVED: `${namespace}/MONTH_DISTRIBUTION:RECEIVED`,
  CAPTURES_MONTH_DISTRIBUTION_ERROR: `${namespace}/MONTH_DISTRIBUTION:ERROR`,

  CAPTURES_OF_COLLECTION_AND_YEAR_REQUEST: `${namespace}/CAPTURES:COLLECTION_AND_YEAR:REQUEST`,
  CAPTURES_OF_COLLECTION_AND_YEAR_RECEIVED: `${namespace}/CAPTURES:COLLECTION_AND_YEAR:RECEIVED`,
  CAPTURES_OF_COLLECTION_AND_YEAR_ERROR: `${namespace}/CAPTURES:COLLECTION_AND_YEAR:ERROR`,

  FIRST_CAPTURE_REQUEST: `${namespace}/FIRST_CAPTURE:REQUEST`,
  FIRST_CAPTURE_RECEIVED: `${namespace}/FIRST_CAPTURE:RECEIVED`,
  FIRST_CAPTURE_ERROR: `${namespace}/FIRST_CAPTURE:ERROR`,

  LAST_CAPTURE_REQUEST: `${namespace}/LAST_CAPTURE:REQUEST`,
  LAST_CAPTURE_RECEIVED: `${namespace}/LAST_CAPTURE:RECEIVED`,
  LAST_CAPTURE_ERROR: `${namespace}/LAST_CAPTURE:ERROR`
};

// actions

/**
 * request captures distribution is used for Sparkline
 */
export const fetchCapturesDistribution = fetchActionSimplified({
  getUrl: ({ url }) => prepareUrl(Config.api_url_sparkline, {
    url: encodeURIComponent(url)
  }),

  isAlreadyInProgress: isCapturesDistributionInProgress,

  actions: [
    actionTypes.CAPTURES_DISTRIBUTION_REQUEST,
    actionTypes.CAPTURES_DISTRIBUTION_RECEIVED,
    actionTypes.CAPTURES_DISTRIBUTION_ERROR
  ]
});

/**
 * fetch captures data for selected url
 *
 * @returns {function(*, *): *}
 */
export const fetchCurrentUrlCapturesDistribution = () => (dispatch, getState) => {
  const url = searchRequestSelector.getSubmittedQueryText(getState());
  const queryType = searchRequestSelector.getImplicitQueryType(getState());
  if (url && queryType === types.URL) {
    return dispatch(fetchCapturesDistribution({ url }));
  }
};

/**
 * request a single year daily captures distribution (v1)
 */
export const fetchYearDetailsV1 = fetchActionSimplified({
  getUrl: ({ url, year }) => prepareUrl(Config.api_url_calendar_captures, {
    url: encodeURIComponent(url),
    year
  }),

  isAlreadyInProgress: isCapturesDetailsInProgress,

  actions: [
    actionTypes.CAPTURES_YEAR_DETAILS_REQUEST,
    actionTypes.CAPTURES_YEAR_DETAILS_RECEIVED,
    actionTypes.CAPTURES_YEAR_DETAILS_ERROR
  ]
});

/**
 * request a single year daily captures distribution (v1)
 */
export const fetchYearDistributionV2 = fetchActionSimplified({
  getUrl: ({ url, year }) => prepareUrl(Config.api_url_captures_distribution_of_year, {
    url: encodeURIComponent(url),
    year
  }),

  isAlreadyInProgress: isCapturesByYearInProgress,

  actions: [
    actionTypes.CAPTURES_YEAR_DISTRIBUTION_REQUEST,
    actionTypes.CAPTURES_YEAR_DISTRIBUTION_RECEIVED,
    actionTypes.CAPTURES_YEAR_DISTRIBUTION_ERROR
  ]
});

/**
 * request a single month daily captures distribution (v2)
 *
 * @param month
 * @return {function(*, *): *}
 */
export function fetchMonthDistributionOfSelectedYear (month) {
  return (dispatch, getState) => {
    const url = searchRequestSelector.getSubmittedQueryText(getState());
    const year = calendarSelector.getSelectedYear(getState());

    return dispatch(fetchMonthDistribution({ url, year, month }));
  };
}

/**
 * request a single month daily captures distribution (v2)
 *
 * @type {function(*=): function(*, *): Promise}
 */
export const fetchMonthDistribution = fetchActionSimplified({
  getUrl: ({ url, year, month }) => prepareUrl(Config.api_url_captures_distribution_of_month, {
    url: encodeURIComponent(url),
    year,
    month: (month + 1).toString().padStart(2, '0')
  }),

  isAlreadyInProgress: isCapturesByMonthInProgress,

  actions: [
    actionTypes.CAPTURES_MONTH_DISTRIBUTION_REQUEST,
    actionTypes.CAPTURES_MONTH_DISTRIBUTION_RECEIVED,
    actionTypes.CAPTURES_MONTH_DISTRIBUTION_ERROR
  ]
});

function processYearDistribution ({ items }) {
  return {
    items: (items || [])
      .reduce((acc, [ts, st, cn]) => {
        acc[ts] = {
          st: restoreStatus(st),
          cn
        };
        return acc;
      }, {})
  };
}

/**
 * request one year captures grouped by collections
 */
export const fetchCapturesGroupedByCollections = fetchActionSimplified({
  getUrl: ({ url, year }) => prepareUrl(Config.api_url_collections_captures_of_year, {
    url: encodeURIComponent(url),
    year
  }),

  isAlreadyInProgress: isCapturesCollectionsInProgress,

  actions: [
    actionTypes.CAPTURES_OF_COLLECTION_AND_YEAR_REQUEST,
    actionTypes.CAPTURES_OF_COLLECTION_AND_YEAR_RECEIVED,
    actionTypes.CAPTURES_OF_COLLECTION_AND_YEAR_ERROR
  ]
});

/**
 * request timestamp for the first capture
 */
export const fetchFirstCapture = fetchActionSimplified({
  getUrl: ({ url }) => prepareUrl(Config.api_url_first_capture, {
    url: encodeURIComponent(url)
  }),

  actions: [
    actionTypes.FIRST_CAPTURE_REQUEST,
    actionTypes.FIRST_CAPTURE_RECEIVED,
    actionTypes.FIRST_CAPTURE_ERROR
  ]
});

/**
 * request timestamp for the last capture
 */
export const fetchLastCapture = fetchActionSimplified({
  getUrl: ({ url }) => prepareUrl(Config.api_url_last_capture, {
    url: encodeURIComponent(url)
  }),

  actions: [
    actionTypes.LAST_CAPTURE_REQUEST,
    actionTypes.LAST_CAPTURE_RECEIVED,
    actionTypes.LAST_CAPTURE_ERROR
  ]
});

/**
 * request all captures for one day
 */
export const fetchAllCapturesOfOneDay = fetchActionSimplified({
  getUrl: ({ url, day }) => prepareUrl(Config.api_url_all_captures_of_a_date, {
    url: encodeURIComponent(url),
    date: day
  }),

  actions: [
    actionTypes.ALL_CAPTURES_OF_DAY_REQUEST,
    actionTypes.ALL_CAPTURES_OF_DAY_RECEIVED,
    actionTypes.ALL_CAPTURES_OF_DAY_ERROR
  ]
});

// processors

function processFirstCapture ({ res }) {
  return {
    firstTimestamp: res,
    firstYear: timestampToYear(res)
  };
}

function processLastCapture ({ res }) {
  return {
    lastTimestamp: res,
    lastYear: timestampToYear(res)
  };
}

// reducer

const startProgressHandler = (subGroupName = null) => (draft, action) => {
  const { updatedAt, url } = action;
  if (!draft[url]) {
    draft[url] = {};
  }

  let subGroup;
  if (subGroupName) {
    subGroup = draft[url][subGroupName];
    if (!subGroup) {
      subGroup = {};
      draft[url][subGroupName] = subGroup;
    }
  } else {
    subGroup = draft[url];
  }

  draft[url].url = url;

  subGroup.inProgress = true;
  subGroup.updatedAt = updatedAt;
};

export const byCaptureKey = 'byCapture';
export const byDayKey = 'byDay';
export const byYear = 'byYear';
export const byMonth = 'byMonth';

function mergeToAllCapturesOfADay (draft, url, timestamp, newValue) {
  if (!has(draft, [url, byDayKey])) {
    set(draft, [url, byDayKey], {});
  }

  if (!has(draft, [url, byDayKey, timestamp])) {
    draft[url][byDayKey][timestamp] = {};
  }

  draft[url][byDayKey][timestamp] = {
    ...draft[url][byDayKey][timestamp],
    ...newValue
  };
}

function mergeToCaptureOfCollectionAndYear (draft, url, year, newValue) {
  if (!has(draft, [url, byCaptureKey])) {
    set(draft, [url, byCaptureKey, {}]);
  }

  if (!has(draft, [url, byCaptureKey, year])) {
    draft[url][byCaptureKey][year] = {};
  }

  draft[url][byCaptureKey][year] = {
    ...draft[url][byCaptureKey][year],
    ...newValue
  };
}

export const mergeToBy = (byTopic) => (draft, url, keys, newValue) => {
  const pathKeys = [url, byTopic, ...keys];

  const lastKey = pathKeys.pop();

  for (const key of pathKeys) {
    if (!(key in draft)) {
      draft[key] = {};
    }

    draft = draft[key];
  }

  draft[lastKey] = {
    ...draft[lastKey],
    ...newValue
  };
};

export const mergeToCapturesByYear = mergeToBy(byYear);
export const mergeToCapturesByMonth = mergeToBy(byMonth);

/**
 * endpoint returns minified status and timestamp
 */
function unpackReceivedCaptures (items, colls, timestampPrefix, padStartOfTimestamp) {
  return items.map(([ts, st, clId]) => ({
    datetime: timestampPrefix + ts.toString().padStart(padStartOfTimestamp, '0'),
    status: restoreStatus(st),
    // unpack collection group index, to the real collection ids
    cl: processCollections(colls[clId])
  }));
}

const initialState = {};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(actionTypes.ALL_CAPTURES_OF_DAY_REQUEST, (draft, { day, url }) => {
      mergeToAllCapturesOfADay(draft, url, day, { inProgress: true });
    })
    .addCase(actionTypes.ALL_CAPTURES_OF_DAY_RECEIVED, (draft, { day, url, res: { items, colls } }) => {
      mergeToAllCapturesOfADay(draft, url, day, {
        inProgress: false,
        items: unpackReceivedCaptures(items, colls, day, 6),
        error: null,
      });
    })
    .addCase(actionTypes.ALL_CAPTURES_OF_DAY_ERROR, (draft, { day, url, error }) => {
      mergeToAllCapturesOfADay(draft, url, day, { inProgress: false, error });
    })
    .addCase(actionTypes.CAPTURES_OF_COLLECTION_AND_YEAR_REQUEST, (draft, { url, year }) => {
      mergeToCaptureOfCollectionAndYear(draft, url, year, { inProgress: true });
    })
    .addCase(actionTypes.CAPTURES_OF_COLLECTION_AND_YEAR_ERROR, (draft, { url, year, error }) => {
      mergeToCaptureOfCollectionAndYear(draft, url, year, { inProgress: false, error });
    })
    .addCase(actionTypes.CAPTURES_OF_COLLECTION_AND_YEAR_RECEIVED, (draft, { url, year, res: { items } }) => {
      mergeToCaptureOfCollectionAndYear(draft, url, year, { inProgress: false, items, error: null });
    })
    .addCase(actionTypes.FIRST_CAPTURE_REQUEST, startProgressHandler('firstCapture'))
    .addCase(actionTypes.FIRST_CAPTURE_ERROR, (draft, action) => {
      const { error, url } = action;
      draft[url].firstError = error;
      draft[url].inProgress = false;
    })
    .addCase(actionTypes.FIRST_CAPTURE_RECEIVED, (draft, action) => {
      const { updatedAt, url } = action;
      if (!draft[url]) {
        draft[url] = processFirstCapture(action);
      } else {
        draft[url] = { ...draft[url], ...processFirstCapture(action) };
      }
      draft[url].inProgress = false;
      draft[url].updatedAt = updatedAt;
    })
    .addCase(actionTypes.LAST_CAPTURE_REQUEST, startProgressHandler('lastCapture'))
    .addCase(actionTypes.LAST_CAPTURE_ERROR, (draft, action) => {
      const { error, url } = action;
      draft[url].lastError = error;
      draft[url].inProgress = false;
    })
    .addCase(actionTypes.LAST_CAPTURE_RECEIVED, (draft, action) => {
      const { updatedAt, url } = action;
      if (!draft[url]) {
        draft[url] = processLastCapture(action);
      } else {
        draft[url] = { ...draft[url], ...processLastCapture(action) };
      }
      draft[url].inProgress = false;
      draft[url].updatedAt = updatedAt;
    })
    .addCase(actionTypes.CAPTURES_DISTRIBUTION_REQUEST, startProgressHandler('distribution'))
    .addCase(actionTypes.CAPTURES_DISTRIBUTION_RECEIVED, (draft, action) => {
      const { updatedAt, url } = action;

      const yearsData = action.res.years || {};
      const years = Object.keys(yearsData).sort();

      const total = Object.values(yearsData).reduce((result, value) => result + value.reduce((acc, v) => acc + v, 0), 0);

      if (!draft[url]) {
        draft[url] = {};
      }

      draft[url].firstTimestamp = action.res.first_ts;
      draft[url].firstYear = Number.parseInt(years[0]);
      draft[url].isLive = action.res.is_live;
      draft[url].lastTimestamp = action.res.last_ts;
      draft[url].lastYear = Number.parseInt(years[years.length - 1]);
      draft[url].noCaptures = action.res.last_ts === null;
      draft[url].distribution = {
        total,
        byYear: action.res.years,
        valid: true,
        inProgress: false,
        updatedAt,
      };
    })
    .addCase(actionTypes.CAPTURES_DISTRIBUTION_ERROR, (draft, { error, updatedAt, url }) => {
      draft[url].distribution = {
        error,
        valid: true,
        inProgress: false,
        updatedAt,
      };
    })
    .addCase(actionTypes.CAPTURES_YEAR_DETAILS_REQUEST, (draft, action) => {
      const { updatedAt, url, year } = action;
      if (!has(draft, [url, 'details'])) {
        set(draft, [url, 'details'], {});
      }
      set(draft, [url, 'details', year], { inProgress: true, updatedAt });
    })
    .addCase(actionTypes.CAPTURES_YEAR_DETAILS_RECEIVED, (draft, { res, updatedAt, url, year }) => {
      set(draft, [url, 'details', year], { list: res, inProgress: false, updatedAt });
    })
    .addCase(actionTypes.CAPTURES_YEAR_DETAILS_ERROR, (draft, { error, updatedAt, url, year }) => {
      set(draft, [url, 'details', year], { error, inProgress: false, updatedAt });
    })
    .addCase(actionTypes.CAPTURES_YEAR_DISTRIBUTION_REQUEST, (draft, { url, year }) => {
      mergeToCapturesByYear(draft, url, [year], { inProgress: true, error: null });
    })
    .addCase(actionTypes.CAPTURES_YEAR_DISTRIBUTION_RECEIVED, (draft, { url, year, res }) => {
      mergeToCapturesByYear(draft, url, [year], { inProgress: false, ...processYearDistribution(res) });
    })
    .addCase(actionTypes.CAPTURES_YEAR_DISTRIBUTION_ERROR, (draft, { url, year, month, error }) => {
      mergeToCapturesByYear(draft, url, [year], { inProgress: false, error });
    })
    .addCase(actionTypes.CAPTURES_MONTH_DISTRIBUTION_REQUEST, (draft, { url, year, month }) => {
      mergeToCapturesByMonth(draft, url, [year, month], { inProgress: true, error: null });
    })
    .addCase(actionTypes.CAPTURES_MONTH_DISTRIBUTION_RECEIVED, (draft, { url, year, month, res }) => {
      mergeToCapturesByMonth(draft, url, [year, month], { inProgress: false, ...processYearDistribution(res) });
    })
    .addCase(actionTypes.CAPTURES_MONTH_DISTRIBUTION_ERROR, (draft, { url, year, month, error }) => {
      mergeToCapturesByMonth(draft, url, [year, month], { inProgress: false, error });
    })
    .addCase(searchResultActionTypes.SEARCH_RESULT_RECEIVE, (draft, action) => {
      let urlKey;
      if (canIUseBetaFeature('search') === 'v2') {
        urlKey = Config.search_v2.uid_field_name;
      } else {
        urlKey = Config.search.uid_field_name;
      }

      getSearchItemsResult(action.res).forEach((snapshot) => {
        const url = snapshot[urlKey];
        if (canIUseBetaFeature('search') === 'v2') {
          draft[url] = {
            firstTimestamp: snapshot.first_captured,
            firstYear: timestampToYear(snapshot.first_captured),
          };
        } else {
          draft[url] = {
            firstYear: snapshot.first_captured,
          };
        }

        draft[url].total = snapshot.capture;

        if (snapshot.last_captured) {
          draft[url].lastYear = Number.parseInt(snapshot.last_captured);
        }
      });
    });
});
