import { createReducer } from '@reduxjs/toolkit';
import has from 'lodash/has';
import { actionTypes as searchResultActionTypes } from './search-result';
import Config from '../../config';
import { getHandler } from '../../utils/media-types-schemes';

const namespace = 'ENTITIES/URL_DETAILS';

export const actionTypes = {
  PREVIEW_IMAGE_OF_SNAPSHOT_LOAD_ERROR: `${namespace}:PREVIEW_IMAGE_OF_SNAPSHOT_LOAD_ERROR`
};

export const previewImageOfSnapshotLoadError = (id) => ({
  type: actionTypes.PREVIEW_IMAGE_OF_SNAPSHOT_LOAD_ERROR,
  payload: { id }
});

export default createReducer({}, (builder) => {
  builder
    .addCase(actionTypes.PREVIEW_IMAGE_OF_SNAPSHOT_LOAD_ERROR, (draft, { payload: { id } }) => {
      draft[id].preview.imageSrc = null;
    })
    .addCase(searchResultActionTypes.SEARCH_RESULT_RECEIVE, (draft, action) => {
      let items;
      let key;
      if ('hits' in action.res) {
        items = action.res.hits;
        key = Config.search_v2.uid_field_name;
      } else {
        items = action.res;
        key = Config.search.uid_field_name;
      }

      items
        .map((snapshot) => {
          // Handle Search v1 results which don't have `host` and `content_type`.
          if (!has(snapshot, 'host')) {
            snapshot.host = snapshot.name;
          }
          if (!has(snapshot, 'content_type')) {
            snapshot.content_type = ['homepage'];
          }
          // TODO: preview should be part of selector
          const handler = getHandler(snapshot.content_type);
          const preview = handler.preview(snapshot);
          return {
            id: snapshot[key],
            preview: preview,
            ...snapshot
          };
        })
        .forEach((item) => {
          draft[item.id] = item;
        });
    });
});
