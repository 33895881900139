import PropTypes from 'prop-types';
import React from 'react';

import { Spinner } from './index';

const ContainerWithSpinner = ({ children, data }) => {
  if (!data || data.inProgress) {
    return (
      <div className="text-center"><Spinner/></div>
    );
  }

  if (Array.isArray(children)) {
    return (
      <div>{children}</div>
    );
  }

  return children || null;
};

ContainerWithSpinner.displayName = 'ContainerWithSpinner';
ContainerWithSpinner.propTypes = {
  data: PropTypes.object
};

export default React.memo(ContainerWithSpinner);
