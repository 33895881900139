import { createAction, createReducer } from '@reduxjs/toolkit';

import Config from '../../config';
import getCurrentYear from '../../utils/datetime/current-year';
import dayOfYearToDate from '../../utils/datetime/day-of-year-to-date';
import getNextDay from '../../utils/datetime/next-day';
import getPreviousDay from '../../utils/datetime/previous-day';

const namespace = 'UI/CALENDAR';

const actionTypes = {
  CALENDAR_NEXT_DAY: `${namespace}/NEXT_DAY`,
  CALENDAR_PREVIOUS_DAY: `${namespace}/PREVIOUS_DAY`,
  CALENDAR_HOVER_DATE: `${namespace}/HOVER`,
  CALENDAR_HOVER_DAY: `${namespace}/HOVER_DAY`,
  CALENDAR_UNHIGHLIGHT: `${namespace}/UNHIGHLIGHT`,
  CALENDAR_SELECT_CAPTURE: `${namespace}/SELECT_CAPTURE`,
  CALENDAR_SELECT_DATE: `${namespace}/SELECT_DATE`,
  CALENDAR_SELECT_NEXT_YEAR: `${namespace}/SELECT_NEXT_YEAR`,
  CALENDAR_SELECT_PREVIOUS_YEAR: `${namespace}/SELECT_PREVIOUS_YEAR`,
  CALENDAR_SELECT_YEAR: `${namespace}/SELECT_YEAR`
};

/**
 * hover one date (year, month, day) in calendar
 *
 * @param date
 * @returns {{year: *, month: *}}
 */
export function hoverCalendarDate (date = null) {
  return {
    type: actionTypes.CALENDAR_HOVER_DATE,
    ...date
  };
}

export function highlightDayOfYear (dayOfYear) {
  return {
    type: actionTypes.CALENDAR_HOVER_DAY,
    payload: { dayOfYear }
  };
}

export const nextDay = createAction(actionTypes.CALENDAR_NEXT_DAY);

export const previousDay = createAction(actionTypes.CALENDAR_PREVIOUS_DAY);

export const unhighlight = () => ({
  type: actionTypes.CALENDAR_UNHIGHLIGHT
});

/**
 * select one date (year, month, day) in calendar
 *
 * @param date
 * @returns {{type: string}}
 */
export function selectCalendarDate (date) {
  return {
    type: actionTypes.CALENDAR_SELECT_DATE,
    ...date
  };
}

/**
 * select one capture
 *
 * @param capture
 * @returns {{type: string, datetime: *}}
 */
export function selectCapture (capture) {
  return {
    type: actionTypes.CALENDAR_SELECT_CAPTURE,
    capture
  };
}

/**
 * select previous year
 *
 * @returns {{type: string}}
 */
export function selectPreviousYear () {
  return {
    type: actionTypes.CALENDAR_SELECT_PREVIOUS_YEAR
  };
}

/**
 * select next year
 *
 * @returns {{type: string}}
 */
export function selectNextYear () {
  return {
    type: actionTypes.CALENDAR_SELECT_NEXT_YEAR
  };
}

/**
 * select an year in calendar
 *
 * @param year
 * @param explicit
 * @returns {{type: string}}
 */
export function selectCalendarYear (year, explicit = false) {
  return {
    type: actionTypes.CALENDAR_SELECT_YEAR,
    payload: { year }
  };
}

const initialState = {
  highlighted: {},
  selected: {
    // explicitly defined selection
    explicit: false,
    year: getCurrentYear()
  }
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(actionTypes.CALENDAR_HOVER_DATE, (draft, action) => {
      draft.highlighted = {
        year: action.year,
        month: action.month,
        day: action.day
      };
    })
    .addCase(actionTypes.CALENDAR_HOVER_DAY, (draft, { payload: { dayOfYear } }) => {
      const d = dayOfYearToDate(draft.selected.year, dayOfYear);
      draft.highlighted.year = draft.selected.year;
      draft.highlighted.month = d.getUTCMonth();
      draft.highlighted.day = d.getUTCDate();
    })
    .addCase(actionTypes.CALENDAR_UNHIGHLIGHT, (draft) => {
      draft.highlighted = {};
    })
    .addCase(actionTypes.CALENDAR_SELECT_CAPTURE, (draft, action) => {
      draft.selected.capture = action.capture;
    })
    .addCase(actionTypes.CALENDAR_SELECT_DATE, (draft, action) => {
      draft.selected = {
        explicit: true,
        year: action.year,
        month: action.month,
        day: action.day
      };
    })
    .addCase(actionTypes.CALENDAR_SELECT_PREVIOUS_YEAR, (draft) => {
      draft.selected.explicit = true;
      draft.selected.year = Math.max(draft.selected.year - 1, Config.calendar.first_year);
    })
    .addCase(actionTypes.CALENDAR_SELECT_NEXT_YEAR, (draft) => {
      draft.selected.explicit = true;
      draft.selected.year = Math.min(draft.selected.year + 1, Config.calendar.last_year);
    })
    .addCase(actionTypes.CALENDAR_SELECT_YEAR, (draft, { payload: { explicit = false, year } }) => {
      draft.selected.explicit = explicit;
      draft.selected.year = year;
    })
    .addCase(actionTypes.CALENDAR_NEXT_DAY, (draft) => {
      const { highlighted } = draft;
      const { month, day } = getNextDay(highlighted.year, highlighted.month, highlighted.day);
      draft.highlighted.month = month;
      draft.highlighted.day = day;
    })
    .addCase(actionTypes.CALENDAR_PREVIOUS_DAY, (draft) => {
      const { highlighted } = draft;
      const { month, day } = getPreviousDay(highlighted.year, highlighted.month, highlighted.day);
      draft.highlighted.month = month;
      draft.highlighted.day = day;
    });
});
