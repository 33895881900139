import { createReducer } from '@reduxjs/toolkit';
import { evalDistance } from '../entities/diff';

import * as searchRequestSelector from '../../selectors/ui/search-request';

const namespace = 'UI/CHANGES_CALENDAR';

const actionTypes = {
  CHANGES_CALENDAR_SHOW_DAY_POPUP: `${namespace}/SHOW_DAY_POPUP`,
  CHANGES_CALENDAR_HIDE_DAY_POPUP: `${namespace}/HIDE_DAY_POPUP`,
  CHANGES_CALENDAR_SELECT_TIMESTAMP_A: `${namespace}/SELECT_TIMESTAMP_A`,
  CHANGES_CALENDAR_SELECT_TIMESTAMP_A_ONLY: `${namespace}/SELECT_TIMESTAMP_A_ONLY`,
  CHANGES_CALENDAR_UNSELECT_TIMESTAMP_A: `${namespace}/UNSELECT_TIMESTAMP_A`,
  CHANGES_CALENDAR_SELECT_TIMESTAMP_B: `${namespace}/SELECT_TIMESTAMP_B`,
  CHANGES_CALENDAR_UNSELECT_TIMESTAMP_B: `${namespace}/UNSELECT_TIMESTAMP_B`,
  CHANGES_CALENDAR_UNSELECT_TIMESTAMPS_A_N_B: `${namespace}/UNSELECT_TIMESTAMPS_A_N_B`
};

// actions

export function showDayPopup (settings) {
  return {
    type: actionTypes.CHANGES_CALENDAR_SHOW_DAY_POPUP,
    payload: { settings }
  };
}

export function hideDayPopup () {
  return {
    type: actionTypes.CHANGES_CALENDAR_HIDE_DAY_POPUP
  };
}

export function selectTimestampA (timestamp) {
  return {
    type: actionTypes.CHANGES_CALENDAR_SELECT_TIMESTAMP_A,
    payload: { timestamp }
  };
}

export function selectTimestampAOnly (timestamp) {
  return {
    type: actionTypes.CHANGES_CALENDAR_SELECT_TIMESTAMP_A_ONLY,
    payload: { timestamp }
  };
}

export function unselectTimestampA () {
  return {
    type: actionTypes.CHANGES_CALENDAR_UNSELECT_TIMESTAMP_A
  };
}

export function selectTimestampB (timestamp) {
  return {
    type: actionTypes.CHANGES_CALENDAR_SELECT_TIMESTAMP_B,
    payload: { timestamp }
  };
}

export function unselectTimestampB () {
  return {
    type: actionTypes.CHANGES_CALENDAR_UNSELECT_TIMESTAMP_B
  };
}

export function unselectTimestampsAnB () {
  return {
    type: actionTypes.CHANGES_CALENDAR_UNSELECT_TIMESTAMPS_A_N_B
  };
}

export function evalDistanceForCurrentUrl (timestamp) {
  return (dispatch, getState) => dispatch(evalDistance({
    url: searchRequestSelector.getSubmittedQueryText(getState()),
    timestamp
  }));
}

// reducer
const initialState = {
  dayPopupSettings: null,
  mainFieldName: 'delta',
  timestampA: null,
  timestampB: null
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(actionTypes.CHANGES_CALENDAR_SHOW_DAY_POPUP, (draft, action) => {
      draft.dayPopupSettings = action.payload.settings;
    })
    .addCase(actionTypes.CHANGES_CALENDAR_HIDE_DAY_POPUP, (draft) => {
      draft.dayPopupSettings = null;
    })
    .addCase(actionTypes.CHANGES_CALENDAR_SELECT_TIMESTAMP_A, (draft, action) => {
      draft.timestampA = action.payload.timestamp;
    })
    .addCase(actionTypes.CHANGES_CALENDAR_SELECT_TIMESTAMP_A_ONLY, (draft, action) => {
      draft.timestampA = action.payload.timestamp;
      draft.timestampB = null;
    })
    .addCase(actionTypes.CHANGES_CALENDAR_UNSELECT_TIMESTAMP_A, (draft) => {
      draft.timestampA = draft.timestampB;
      draft.timestampB = null;
    })
    .addCase(actionTypes.CHANGES_CALENDAR_SELECT_TIMESTAMP_B, (draft, action) => {
      draft.timestampB = action.payload.timestamp;
    })
    .addCase(actionTypes.CHANGES_CALENDAR_UNSELECT_TIMESTAMP_B, (draft) => {
      draft.timestampB = null;
    })
    .addCase(actionTypes.CHANGES_CALENDAR_UNSELECT_TIMESTAMPS_A_N_B, (draft) => {
      draft.timestampA = null;
      draft.timestampB = null;
    });
});
