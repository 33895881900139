/**
 * resolve promise after awhile
 *
 * @param msec
 * @returns {Promise}
 */
export function sleep (msec = 0) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

/**
 * create sleep promise
 *
 * @param msec
 * @returns {function()}
 */
export function willSleep (msec = 0) {
  return () => sleep(msec);
}
